export const ApiUrl = {
  //--- Account
  Login: "api/Account/Login",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPassword: "​api​/Account​/ResetPassword",

  //--- Email Generated
  GetListEmailGenerated: "api/admin/EmailGenerated/GetListEmailGenerated",
  GetDetailEmailGenerated: "api/admin/EmailGenerated/GetDetailEmailGenerated",

  //--- Email Template
  GetListEmailTemplates: "api/admin/EmailTemplate/GetListEmailTemplates",
  GetDetailEmailTemplate: "api/admin/EmailTemplate/GetDetailEmailTemplate",
  CreateEmailTemplate: "api/admin/EmailTemplate/CreateEmailTemplate",
  UpdateEmailTemplate: "api/admin/EmailTemplate/UpdateEmailTemplate",
  DeleteEmailTemplate: "api/admin/EmailTemplate/DeleteEmailTemplate",

  //--- Contact Management
  GetListContactManagement: "api/admin/EmailGenerated/GetListContactToAdmin",
  GetContactDetail: "api/admin/EmailGenerated/GetDetailContactToAdmin",
  ReplyUserContactByEmail: "api/admin/EmailGenerated/ReplyUserContactByEmail",
  GetDetailEmailTemplate: "api/admin/EmailTemplate/GetDetailEmailTemplate",

  //--- Role Management
  GetListRoleManagement: "api/admin/Role/GetListRole",
  GetDetailRoleManagement: "api/admin/Role/GetDetailRole",
  CreateRoleManagement: "api/admin/Role/CreateRole",
  UpdateRoleManagement: "api/admin/Role/UpdateRole",
  DeleteRoleManagement: "api/admin/Role/DeleteRole",

  //--- Map
  CreateMap: "api/admin/Map/create-map",
  GetMapById: "api/admin/Map/get-map-by-id",
  GetAllBaseMapSetting: "api/admin/BaseMapSetting/get-all",
  GetPgSchema: "api/admin/PgTable/get-pg-schema",
  GetPageByTable: "api/admin/PgTable/get-pg-table",
  UpdateMap: "api/admin/Map/update-map",
  GetPgTable: "api/admin/PgTable/get-pg-table",
  DeleteMap: "api/admin/Map/delete-map",

  //--- Planning
  GetListPlanning: "api/admin/Planning/GetListPlanning",
  GetLookUpPlanning: "api/admin/Planning/GetLookUpPlanning",
  GetLookUpPlanningStatus: "api/admin/Planning/GetLookUpPlanningStatus",
  GetLookUpPlanningLevel: "api/admin/Planning/GetLookUpPlanningLevel",
  GetLookUpPlanningType: "api/admin/Planning/GetLookUpPlanningType",
  GetLookUpDocumentType: "api/admin/Planning/GetLookUpDocumentType",
  GetDetailPlaning: "api/admin/Planning/GetDetailPlaning",
  CreatePlanning: "api/admin/Planning/CreatePlanning",
  UpdatePlanning: "api/admin/Planning/UpdatePlanning",
  DeletePlanning: "api/admin/Planning/DeletePlanning",
  PlanningRelationshipType:
    "api/admin/Planning/get-all-planning-relationship-type",
  PlanningRelationshipTypeById:
    "api/admin/Planning/get-all-planning-relationship-by-planning-id",
  PlanningApprovedById:
    "api/admin/Planning/get-look-up-approved-planning-by-id",
  PutPlanningRelationship:
    "api/admin/Planning/update-multiple-planning-relationship",
  GetAllBoundaries: "api/admin/Planning/get-all-boundaries",
  CheckExistedPlanning: "api/admin/Planning/CheckExistedPlanning",

  //--- Consultant Community
  GetListConsultCommunity:
    "api/admin/ConsultantCommunity/GetListConsultCommunity",
  GetDetailConsultCommunity:
    "api/admin/ConsultantCommunity/GetDetailConsultCommunity",
  CreateConsultCommunity:
    "api/admin/ConsultantCommunity/CreateConsultCommunity",
  UpdateConsultCommunity:
    "api/admin/ConsultantCommunity/UpdateConsultCommunity",
  DeleteConsultCommunity:
    "api/admin/ConsultantCommunity/DeleteConsultCommunity",
  GetConsultCommunityStatusLookup:
    "api/admin/ConsultantCommunity/GetConsultCommunityStatusLookup",
  GetConsultCommunityByPlanning:
    "api/admin/ConsultantCommunity/GetConsultCommunityByPlanning",
  ExportConsultantList: "api/ExportData/ExportConsultantList",

  //--- Statement
  GetListStatement: "api/admin/Statement/GetListStatement",
  // GetDetailStatement: "​api​/admin​/Statement​/GetStatementByPlanning",
  CreateStatement: "api/admin/Statement/CreateStatement",
  UpdateStatement: "api/admin/Statement/UpdateStatement",
  DeleteStatement: "api/admin/Statement/DeleteStatement",
  GetStatementStatusLookup: "api/admin/Statement/GetStatementStatusLookup",
  GetListServiceLink: "api/admin/Statement/GetListServiceLink",
  SearchStatement: "api/admin/Statement/search-statement-by",
  GetStatementReport: "api/admin/Statement/GetStatementReport",

  // Home page
  GetListHomePage: "api/admin/HomePage/GetListHomePage",
  GetDetailHomePage: "api/admin/HomePage/GetDetailHomePage",
  CreateHomePage: "api/admin/HomePage/CreateHomePage",
  UpdateHomePage: "api/admin/HomePage/UpdateHomePage",
  DeleteHomePage: "api/admin/HomePage/DeleteHomePage",
  DeleteAvatar: "api/admin/HomePage/DeleteAvatar",

  // User Management
  GetListUserManagement: "api/admin/User/GetUserManagement",
  GetDetailUserManagement: "api/admin/User/GetUserDetail",
  CreateUserManagement: "api/admin/User/CreateUser",
  UpdateUserManagement: "api/admin/User/UpdateUser",
  DeleteUserManagement: "api/admin/HomePage/DeleteHomePage",
  ActiveUserManagement: "api/admin/User/ActiveUser",
  ResetPasswordUserManagement: "api/admin/User/ResetPassword",
  GetRoleLookupUserManagement: "api/admin/Role/GetRoleLookup",
  ActiveUser: "api/admin/User/ActiveUser",
  DeActiveUser: "api/admin/User/DeActiveUser",

  //ApprovalAgencyLevel
  ApprovalAgencyLevel:
    "api/admin/ApprovalAgencyLevel/get-approval-agency-level",
  GetLookupDistrict: "api/admin/Administrative/GetLookupDistrict",

  //Document
  GetAllDocumentByPlanning: "api/admin/Document/GetAllDocumentByParentId",
  CreateFolder: "api/admin/Document/CreateDocumentFolder",
  ReNameDocument: "api/admin/Document/RenameDocument",
  CreateFile: "api/admin/Document/UploadDocument",
  RemoveDocument: "api/admin/Document/DeleteDocument",
  MoveDocument: "api/admin/Document/MoveDocument",
  SearchDocument: "api/admin/Document/SearchDocument",
  SetDocumentStatus: "api/admin/Document/SetDocumentStatus",
  DownloadDocument: "api/admin/Document/DownloadDocument",

  // CommuneManagement
  GetListCommune: "api/admin/Administrative/GetListCommune",
  GetDetailCommune: "api/admin/Administrative/GetDetailCommune",
  GetDetailCommune: "api/admin/Administrative/GetDetailCommune",
  CreateCommune: "api/admin/Administrative/CreateCommune",
  UpdateCommune: "api/admin/Administrative/UpdateCommune",
  DeleteCommune: "api/admin/Administrative/DeleteCommune",
  GetDistrictByProvinceId: "api/admin/Administrative/GetLookupDistrict",
  GetLookupCommune: "api/admin/Administrative/GetLookupCommune",

  // DistrictManagement
  GetListDistrict: "api/admin/Administrative/GetListDistrict",
  GetDetailDistrict: "api/admin/Administrative/GetDetailDistrict",
  GetLookupDistrict: "api/admin/Administrative/GetLookupDistrict",
  CreateDistrict: "api/admin/Administrative/CreateDistrict",
  UpdateDistrict: "api/admin/Administrative/UpdateDistrict",
  DeleteDistrict: "api/admin/Administrative/DeleteDistrict",

  // ProvinceManagement
  GetListProvince: "api/admin/Administrative/GetListProvince",
  GetDetailProvince: "api/admin/Administrative/GetDetailProvince",
  CreateProvince: "api/admin/Administrative/CreateProvince",
  UpdateProvince: "api/admin/Administrative/UpdateProvince",
  DeleteProvince: "api/admin/Administrative/DeleteProvince",
  GetLookupProvince: "api/admin/Administrative/GetLookupProvince",

  //Link Group Management
  GetListLinkGroup: "api/admin/Administrative/GetListLinkGroup",
  GetDetailLinkGroup: "api/admin/Administrative/GetDetailLinkGroup",
  CreateLinkGroup: "api/admin/Administrative/CreateLinkGroup",
  UpdateLinkGroup: "api/admin/Administrative/UpdateLinkGroup",
  DeleteLinkGroup: "api/admin/Administrative/DeleteLinkGroup",

  //Service Link Management
  GetListServiceLink: "api/admin/Administrative/GetListLinkService",
  GetDetailServiceLink: "api/admin/Administrative/GetDetailLinkService",
  CreateServiceLink: "api/admin/Administrative/CreateLinkService",
  UpdateServiceLink: "api/admin/Administrative/UpdateLinkService",
  DeleteServiceLink: "api/admin/Administrative/DeleteLinkService",
  GetLookupLinkGroup: "api/admin/Administrative/GetLookupLinkGroup",

  //Userlog
  GetAllLogHistory: "api/admin/LogHistory/GetAllLogHistory",

  //Land Type
  GetListLandType: "api/admin/Land/GetListLandType",
  LookupLandType: "api/admin/Land/LookupLandType",
  GetLandTypeById: "api/admin/Land/GetLandTypeById",
  CreateLandType: "api/admin/Land/CreateLandType",
  UpdateLandType: "api/admin/Land/UpdateLandType",
  DeleteLandType: "api/admin/Land/DeleteLandType",

  //Land Type Detail
  GetListLandTypeDetail: "api/admin/Land/GetListLandTypeDetail",
  GetLandTypeDetailById: "api/admin/Land/GetLandTypeDetailById",
  CreateLandTypeDetail: "api/admin/Land/CreateLandTypeDetail",
  UpdateLandTypeDetail: "api/admin/Land/UpdateLandTypeDetail",
  DeleteLandTypeDetail: "api/admin/Land/DeleteLandTypeDetail",

  //Opinion Form
  CreateFormTemplate: "api/admin/ConsultantCommunity/CreateFormTemplate",
  UpdateFormTemplate: "api/admin/ConsultantCommunity/UpdateFormTemplate",
  GetListFormTemplate: "api/admin/ConsultantCommunity/GetListFormTemplate",
  GetDetailFormTemplate: "api/admin/ConsultantCommunity/GetDetailFormTemplate",
  DeleteFormTemplate: "api/admin/ConsultantCommunity/DeleteFormTemplate",
  GetLookupFormExample: "api/admin/ConsultantCommunity/GetLookupFormExample",

  GetDetailFeedback: "api/admin/ConsultantCommunityAdmin/GetDetailFeedback",
  SaveReply: "api/admin/ConsultantCommunityAdmin/SaveReply",

  //utils-shape-file
  Shape_File_Step_1: "api/admin/Utils/step-1-import-and-unzip-file",
  Shape_File_Step_2: "api/admin/Utils/step-2-import-shape-file-to-postgis",
  Shape_File_Step_3: "api/admin/Utils/step-3-created-sld-and-import-to-postgis",
  Shape_File_Step_4: "api/admin/Utils/step-4-publish",
  // Shape_File_Publish_Finish: "api/admin/Utils/publish-again",

  //utils-tiff-file
  Import_Tiff_File: "api/admin/Utils/import-tif",

    //News
    News_Create: "api/admin/News/Create",
    News_Update: "api/admin/News/Update",
    News_Delete: "api/admin/News/Delete",
    News_Restore: "api/admin/News/Restore/{id}",
    News_EmptyTrash: "api/admin/News/EmptyTrash",
    News_ChangeActiveStatus: "api/admin/News/ChangeActiveStatus",
    News_ChangeHotStatus: "api/admin/News/ChangeHotStatus",
    News_ChangeFeatureStatus: "api/admin/News/ChangeFeatureStatus",
    News_GetListAll: "api/admin/News/GetListAll",
    News_GetListByCat: "api/admin/News/GetListByCat",
    News_GetListHot: "api/admin/News/GetListHot",
    News_GetListByStatus: "api/admin/News/GetListByStatus",
    News_GetDetail: "api/admin/News/GetDetail/{id}",

  DownloadFile: "api/cms/Planning/DownloadFileBinary",

  //--- Document Repository
  DocumentRepositoryCreateFolder:
    "api/admin/DocumentUpload/CreateDocumentFolder",
  DocumentRepositoryUploadDocument: "api/admin/DocumentUpload/UploadDocument",
  DocumentRepositoryDownloadDocument:
    "api/admin/DocumentUpload/DownloadDocument",
  DocumentRepositoryDeleteDocument: "api/admin/DocumentUpload/DeleteDocument",
  DocumentRepositoryGetAllDocumentByParentId:
    "api/admin/DocumentUpload/GetAllDocumentByParentId",
  DocumentRepositoryMoveDocument: "api/admin/DocumentUpload/MoveDocument",
  DocumentRepositorySearchDocument: "api/admin/DocumentUpload/SearchDocument",
  DocumentRepositoryRenameDocument: "api/admin/DocumentUpload/RenameDocument",
  DocumentRepositoryGetParentDocumentById:
    "api/admin/DocumentUpload/GetParentDocumentById",
  // Security Matrix
  GetSecurityMatrixDetail: "api/admin/Permission/GetSecurityMatrixDetail",
  GetActionLookup: "api/admin/Permission/GetActionLookup",
  GetScreenLookup: "api/admin/Permission/GetScreenLookup",
  UpdateSecurityMatrix: "api/admin/Permission/UpdateSecurityMatrix",

};
